import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'


const FaqSection = () => { 


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
        <h4 className="heading-h2"><span className="heading-h3 bluecolor">FAQ</span>
            Frequently Asked Questions</h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
        <div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                What is a Paxful Clone?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Paxful Clone is a readymade P2P exchange software solution that comes with a fundamental feature set and functionalities to launch a P2P crypto exchange like Paxful. Our solution comes up with a customization option that allows you to integrate additional features as per your business needs.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                How much does it cost to build a P2P crypto exchange like Paxful?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">The approximate cost to build a P2P exchange with our Paxful clone script would range between $15,000 to $25,000. Be sure that, this is not an exact cost, and it may vary depending on the add-on feature integration, UI/UX design, and revenue streams you prefer to implement.  
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div >
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                What are the revenue streaming modules integrated in the Paxful clone script?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Our Paxful like exchange helps you earn revenue from trading fees, crypto withdrawal fees, escrow service fees, and ads-listing fees from traders. Apart from that, you can earn from business collaborations and partnerships.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div >
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                How long does it take to launch an exchange using a Paxful clone script?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">With our Paxful clone, you can build a peer-to-peer cryptocurrency exchange within 10 days as it holds the essential features. However, the additional feature integration may take some more days.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
          <div >
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                What makes Coinsclone's Paxful clone script trustworthy?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">We prefer industry-leading tech stacks to create a Paxful clone and enhance its standards through streamlined development processes. Also, Our software is completely tested and verified for security, reliability, and performance by a team of blockchain professionals. Thus our script is trustworthy and capable of meeting your business requirements well.
                </div>
              </Accordion.Collapse>
            </div>
          </div>
        </Accordion>
      </Container>
    </section>
  )
}

export default FaqSection