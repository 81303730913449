import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'




const HowOur = () => {

  return (
    <section className="how-our mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h2 className="heading-h2"><span className="heading-h3 bluecolor">How does our</span>
          Paxful Clone work?</h2>
        </div>
        <img width="1110px" height="1610px" src="https://coinsclone.mo.cloudinary.net/images/paxful-new/paxful-workflow.webp" alt="Working flow of our Paxful clone software" />
        <div className="text-center mt-25">
        <ButtonComponent />
        </div>
      </div>
    </section>
  )
}

export default HowOur