import React from 'react'



const TechStack = () => {


  return (
    <section className="stack mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 mx-auto text-center">
          <h4 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Technologies Used</span> for</span>
              Our Paxful Clone Script</h4>
            <p className="pharagraph head">We prefer the most recent and innovative technology stacks for developing the Paxful clone software ideal for websites and mobile apps. Our solutions are compatible with web and mobile platforms like Android, and iOS while maintaining the essence of Paxful crypto exchange.
            </p>
            <div className="quick text-center">
              <img  width="1110px" height="auto" src="https://coinsclone.mo.cloudinary.net/images/binance/technologies-stack.svg" alt="Technologies Stack" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TechStack