import React from 'react'


const SecurityofOur = () => {

  return (
    <section className="security pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
          <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Security Features</span> of </span>
            Our Paxful Clone Script</h3>
            <p className="pharagraph head">We enable advanced security features in our Paxful exchange clone to eliminate fraudulent activities.</p>
          </div>
        </div>
        <div className="binanceflexbox mt-0" >
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="31.985" height="34.515" viewBox="0 0 31.985 34.515">
                  <g id="_2FA" data-name="2FA" transform="translate(0)">
                    <path id="Path_5209" data-name="Path 5209" d="M33.332,0c.559.088,1.132.148,1.685.263A13.762,13.762,0,0,1,45.9,11.552a8.973,8.973,0,0,1,.135,1.274c.02,1.112.007,2.224.007,3.377a10.665,10.665,0,0,0-7.535-.054V13.877a6.564,6.564,0,0,0-.66-2.925A6.1,6.1,0,0,0,26.511,12.1a7.374,7.374,0,0,0-.229,1.624c-.034,1.334-.013,2.676-.007,4.01,0,.067.013.128.02.209h2v-.4c.007-1.4-.034-2.79.04-4.179a4.063,4.063,0,0,1,8.1-.007c.067,1.206.034,2.426.034,3.639a.479.479,0,0,1-.168.3c-.674.607-1.348,1.213-2.035,1.806a.516.516,0,0,1-.377.115.93.93,0,0,1-.775-1.024c-.007-1.6,0-3.215,0-4.819v-.391H31.128a.579.579,0,0,0-.027.142c0,1.766-.02,3.525.013,5.291a2.841,2.841,0,0,0,1.624,2.534.609.609,0,0,1,.108.067c-.377,1.126-.741,2.224-1.126,3.37a3.91,3.91,0,0,1-2.4-1.348,4.006,4.006,0,0,1-1.011-2.655H26.3a4.977,4.977,0,0,0,.27,1.813,6.008,6.008,0,0,0,4.83,4.188c.256.047.3.148.337.377.135.829.31,1.651.479,2.473a4.109,4.109,0,0,0,.135.411,7.878,7.878,0,0,1-2.709-.438,8.687,8.687,0,0,1-5.553-5.553,8.516,8.516,0,0,1-.452-2.878c0-2.379-.007-4.765,0-7.144a8.759,8.759,0,1,1,17.514-.383c0,.067.006.128.012.209h2.022c-.034-.452-.04-.91-.1-1.355A10.63,10.63,0,0,0,39.33,5.237a10.382,10.382,0,0,0-5.291-2.406,10.787,10.787,0,0,0-12.151,8.236,12.645,12.645,0,0,0-.283,2.568c-.034,2.379-.02,4.765-.013,7.144a10.605,10.605,0,0,0,3.9,8.418,10.413,10.413,0,0,0,7.05,2.487,3.14,3.14,0,0,0,.438-.013.545.545,0,0,1,.553.263c.559.674,1.152,1.314,1.732,1.968a1.5,1.5,0,0,0,.222.189c-.013.034-.02.061-.034.094l-1.779.263c-.121.02-.236.047-.357.067H31.431a1.763,1.763,0,0,0-.256-.061,13.164,13.164,0,0,1-7.5-3.093A13.509,13.509,0,0,1,18.707,20.67c-.007-2.291-.013-4.583.007-6.874a15.109,15.109,0,0,1,.162-2.211,13.4,13.4,0,0,1,3.6-7.305A13.562,13.562,0,0,1,29.76.263C30.319.148,30.885.088,31.445,0Z" transform="translate(-18.7)" fill="gray" />
                    <path id="Path_5210" data-name="Path 5210" d="M249.319,276.373c-.505-.088-1.024-.128-1.523-.263a8.311,8.311,0,0,1-6.288-6.308,8.419,8.419,0,0,1,3.727-9.1,8.209,8.209,0,0,1,9.186.094,8.072,8.072,0,0,1,3.727,5.789,8.465,8.465,0,0,1-5,9.065,8.2,8.2,0,0,1-2.628.667,2.806,2.806,0,0,0-.33.054Zm3.72-10.554a3.615,3.615,0,0,0-.762-2.662,3.2,3.2,0,0,0-2.5-1.186,3.093,3.093,0,0,0-2.049.728,3.464,3.464,0,0,0-1.24,3.12c-.216.013-.391.027-.566.034v6.9h7.69v-6.908C253.437,265.846,253.275,265.833,253.039,265.819Z" transform="translate(-226.263 -241.867)" fill="#b0b0b0" />
                    <path id="Path_5211" data-name="Path 5211" d="M340.3,389.037V386.2h3.646v2.837Z" transform="translate(-318.625 -360.172)" fill="#b0b0b0" />
                    <path id="Path_5212" data-name="Path 5212" d="M351.182,330.168h-2.46a1.525,1.525,0,0,1,.5-1.577,1.187,1.187,0,0,1,1.361-.074C351.222,328.881,351.256,329.488,351.182,330.168Z" transform="translate(-326.454 -306.209)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Two-factor
                  Authentication</h5> </div>
            </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="36.702" height="36.699" viewBox="0 0 36.702 36.699">
                  <g id="Escrow_protection" data-name="Escrow protection" transform="translate(0.004)">
                    <path id="Path_8930" data-name="Path 8930" d="M847.969,461.654c-.836-.4-1.7-.752-2.5-1.22a17.952,17.952,0,0,1-9.113-13.731,18.332,18.332,0,0,1-.134-2.286c-.018-2.042-.006-4.085-.006-6.128v-.456a13.913,13.913,0,0,0,11.824-4.746,14.482,14.482,0,0,0,5.351,3.812,15.232,15.232,0,0,0,6.478.942v6.648a18.223,18.223,0,0,1-11.47,17.024c-.1.04-.189.094-.284.142Zm-7.494-19.693c.043,1.418-.025,2.826.147,4.2a13.587,13.587,0,0,0,7.189,10.664.513.513,0,0,0,.444.008,24.383,24.383,0,0,0,2.711-1.916c3.868-3.477,4.972-7.958,4.543-12.968a1.512,1.512,0,0,0-.178-.052,18.082,18.082,0,0,1-7.092-2.914c-.185-.127-.292-.052-.441.033-.921.525-1.826,1.084-2.777,1.551A17.5,17.5,0,0,1,840.475,441.961Z" transform="translate(-829.692 -424.956)" fill="gray" />
                    <path id="Path_8931" data-name="Path 8931" d="M1014.27,399.763a2.87,2.87,0,0,0-.3.231q-1.537,1.529-3.066,3.066a2.357,2.357,0,0,0-.217.287l-1.537-1.539.967-.926-.045-.116h-.357q-3.279,0-6.558-.007a.551.551,0,0,1-.412-.169c-1.014-1.659-2.01-3.33-3.029-5.029l1.84-1.107.536.891c.608,1.013,1.212,2.029,1.832,3.034a.5.5,0,0,0,.343.226c1.9.014,3.8.009,5.7.008a.475.475,0,0,0,.1-.025l-.969-.929,1.573-1.561c.022.021.116.107.206.2l3.093,3.09a3.056,3.056,0,0,0,.3.233Z" transform="translate(-977.572 -390.015)" fill="gray" />
                    <path id="Path_8932" data-name="Path 8932" d="M768,399.614a2.339,2.339,0,0,0,.272-.208q1.546-1.569,3.086-3.143c.074-.076.154-.146.2-.193l1.517,1.514-1.077,1c.222.017.32.031.419.031q2.741,0,5.48.007a.477.477,0,0,0,.479-.269c.763-1.294,1.54-2.579,2.331-3.9l1.832,1.1c-.05.1-.089.177-.135.255-.938,1.566-1.88,3.128-2.81,4.7a.478.478,0,0,1-.478.269c-2.2-.01-4.393-.006-6.59-.006h-.456l1.05.994-1.562,1.547c-.048-.046-.141-.129-.229-.218l-3.086-3.144a1.883,1.883,0,0,0-.247-.182Z" transform="translate(-768 -390.009)" fill="gray" />
                    <path id="Path_8933" data-name="Path 8933" d="M914.68,348l1.807,1.124-1.506,2.506-1.841-1.1c.208-.347.411-.678.6-1.014.291-.5.575-1.008.863-1.512Z" transform="translate(-899.269 -348)" fill="gray" />
                    <path id="Path_8934" data-name="Path 8934" d="M974.094,348c.148.294.287.594.447.882.3.55.622,1.1.945,1.662l-1.865,1.065-1.445-2.535L973.95,348Z" transform="translate(-952.662 -348)" fill="gray" />
                    <path id="Path_8935" data-name="Path 8935" d="M914.013,523.193a11.879,11.879,0,0,1-5.124,10.632.369.369,0,0,1-.5.009,11.512,11.512,0,0,1-4.987-7.993c-.131-.857-.135-1.733-.2-2.617.383-.116.709-.214,1.035-.314a18.547,18.547,0,0,0,4.08-1.8.514.514,0,0,1,.611-.012,19.327,19.327,0,0,0,5.085,2.095Z" transform="translate(-890.282 -504.466)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Escrow Protection</h5>
              </div>
            </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Jail_login_guard" data-name="Jail login guard" xmlns="http://www.w3.org/2000/svg" width="37.377" height="30.898" viewBox="0 0 37.377 30.898">
                  <path id="Path_8921" data-name="Path 8921" d="M741.377,467.563v19.273a2.183,2.183,0,0,1-2.385,1.464q-16.394-.033-32.789-.013c-.134,0-.268,0-.4,0a1.821,1.821,0,0,1-1.516-.871,5.979,5.979,0,0,1-.286-.577V467.563c.121,0,.243.009.364.009h36.649C741.134,467.572,741.256,467.566,741.377,467.563Zm-18.719,16.708h2.881a1.862,1.862,0,0,0,1.89-1.827q.022-2.5,0-5a1.529,1.529,0,0,0-.875-1.492.868.868,0,0,1-.58-1.085.762.762,0,0,0,0-.109,3.284,3.284,0,0,0-5.869-1.9,3.593,3.593,0,0,0-.7,2.5c.008.166.024.306-.205.4a1.815,1.815,0,0,0-1.253,1.854q0,2.316,0,4.632a1.88,1.88,0,0,0,2.018,2.032C720.859,484.275,721.759,484.271,722.659,484.271Z" transform="translate(-704 -457.402)" fill="gray" />
                  <path id="Path_8922" data-name="Path 8922" d="M741.377,329.853v6.5c-.121,0-.243.009-.364.009H704.364c-.121,0-.243-.006-.364-.009v-6.5a2.133,2.133,0,0,1,2.339-1.473q16.423.034,32.845.013c.134,0,.268-.006.4,0a1.815,1.815,0,0,1,1.647,1.133C741.282,329.636,741.329,329.745,741.377,329.853ZM732,331.289h-2.115c-1.094,0-2.188-.009-3.282.008a1.08,1.08,0,0,0-.36,2.091,2.007,2.007,0,0,0,.644.087q5.106.008,10.212,0a2.319,2.319,0,0,0,.47-.039,1.024,1.024,0,0,0,.812-.751,1.08,1.08,0,0,0-1.129-1.4C735.5,331.284,733.753,331.289,732,331.289Zm-22.016,1.11a1.564,1.564,0,1,0-1.564,1.542A1.57,1.57,0,0,0,709.986,332.4Zm4.536-.015a1.559,1.559,0,0,0-1.569-1.572,1.564,1.564,0,1,0,.025,3.129A1.553,1.553,0,0,0,714.522,332.384Z" transform="translate(-704 -328.38)" fill="gray" />
                  <path id="Path_8923" data-name="Path 8923" d="M925.413,608.5h5.058v4.206h-5.058Z" transform="translate(-909.249 -588.051)" fill="#b0b0b0" />
                  <path id="Path_8924" data-name="Path 8924" d="M947.157,554.589h-2.178a8.254,8.254,0,0,1,.006-.913,1.092,1.092,0,0,1,2.168.053C947.176,554,947.157,554.282,947.157,554.589Z" transform="translate(-927.377 -536.361)" fill="#b0b0b0" />
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Jail Login Guard</h5>
              </div></div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="34.646" height="32.486" viewBox="0 0 34.646 32.486">
                  <g id="SSL_integration" data-name="SSL integration" transform="translate(0 0.002)">
                    <path id="Path_5245" data-name="Path 5245" d="M-1,378.752a3.382,3.382,0,0,1,.964-1.779,3.052,3.052,0,0,1,4.92.971c.027.061.061.121.094.2h8.29v-2.015H15.3v-2.211a3.037,3.037,0,0,0,2.029-.013v2.2h2.035v2.029h8.317a3.055,3.055,0,0,1,2.359-1.961A3.119,3.119,0,0,1,33.6,378.6a3.072,3.072,0,0,1-5.81,1.826c-.067-.148-.121-.256-.33-.256-2.642.007-5.277.007-7.919.007-.04,0-.088.007-.155.013v1.988h-6.1V380.2H4.971a3.027,3.027,0,0,1-2.291,1.948,3.113,3.113,0,0,1-3.619-2.386A1.549,1.549,0,0,0-1,379.568C-1,379.292-1,379.022-1,378.752Z" transform="translate(1 -349.716)" fill="#b0b0b0" />
                    <path id="Path_5246" data-name="Path 5246" d="M70.5,93.757H63.428A3.051,3.051,0,0,1,60.2,90.536V76.592c0-.357.047-.714.067-1.092h26.3v4.037H83.559a5.075,5.075,0,0,0-.081,10.15c1.024.013,2.049,0,3.093,0V93.73H76.281a3.031,3.031,0,0,0-.782-3.195,2.939,2.939,0,0,0-2.312-.849A3.107,3.107,0,0,0,70.5,93.757Z" transform="translate(-56.075 -71.426)" fill="gray" />
                    <path id="Path_5247" data-name="Path 5247" d="M75.9,17.06A5.083,5.083,0,0,1,79.1,15.126a5.507,5.507,0,0,1,.944-.067q9.4-.01,18.81,0h.344v2.008C91.455,17.06,83.711,17.06,75.9,17.06Z" transform="translate(-70.717 -15.056)" fill="gray" />
                    <path id="Path_5248" data-name="Path 5248" d="M369.819,165.63v6.059a1.627,1.627,0,0,1-.168.02c-1.644,0-3.289.013-4.933-.007a3.035,3.035,0,0,1-1.227-5.8,3.247,3.247,0,0,1,1.206-.276c1.658-.034,3.309-.013,4.967-.013C369.7,165.617,369.745,165.623,369.819,165.63Zm-3.06,4.03v-1.988h-1.988v1.988Z" transform="translate(-337.274 -155.464)" fill="gray" />
                    <path id="Path_5249" data-name="Path 5249" d="M243.029,317.3a1.014,1.014,0,1,1-1.024-1A1.027,1.027,0,0,1,243.029,317.3Z" transform="translate(-224.69 -295.997)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">SSL Integration
                </h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="End_to_End_Encryption" data-name="End to End Encryption" xmlns="http://www.w3.org/2000/svg" width="34.506" height="34.514" viewBox="0 0 34.506 34.514">
                  <path id="Path_5223" data-name="Path 5223" d="M155,1a18.4,18.4,0,0,1,1.82.452,7.312,7.312,0,0,1,4.906,6.827c.04,2.473.013,4.94.013,7.413v1.227a9.268,9.268,0,0,0-6.066-3.687v-.364c0-1.469.007-2.945-.007-4.414A1.341,1.341,0,0,0,153.9,7.14a1.358,1.358,0,0,0-.917,1.348c-.007,1.449,0,2.9,0,4.347v.391a9.229,9.229,0,0,0-6.032,3.66c-.013-.128-.027-.209-.034-.283,0-2.709-.013-5.412,0-8.121a7.4,7.4,0,0,1,5.883-7.32c.283-.067.573-.108.856-.162Z" transform="translate(-137.077 -1)" fill="gray" />
                  <path id="Path_5224" data-name="Path 5224" d="M392.6,153.826a3.369,3.369,0,0,1-.849,1.8,7.471,7.471,0,0,1-1.247.89.429.429,0,0,0-.27.425c.013,1.348,0,2.7.007,4.044a.353.353,0,0,1-.2.377q-2.992,1.527-5.985,3.08a1.5,1.5,0,0,1-.155.061,1.7,1.7,0,0,1,.04-.236c.148-.674.29-1.355.452-2.029a.6.6,0,0,1,.27-.364c1.092-.58,2.184-1.146,3.289-1.7a.405.405,0,0,0,.27-.431q-.02-1.415,0-2.831a.345.345,0,0,0-.249-.371,3.352,3.352,0,1,1,4.549-3.761,1.921,1.921,0,0,0,.067.182C392.6,153.24,392.6,153.536,392.6,153.826Z" transform="translate(-358.094 -140.01)" fill="gray" />
                  <path id="Path_5225" data-name="Path 5225" d="M291.394,419.778a12.887,12.887,0,0,1-1.51-.62A2.774,2.774,0,0,1,288.7,417.7a.363.363,0,0,0-.418-.276q-1.9.02-3.8,0a.811.811,0,0,1-.5-.2c-.627-.559-1.233-1.139-1.88-1.746.108-.034.168-.061.243-.081.566-.182,1.132-.35,1.692-.553.182-.067.317-.088.438.081a1.3,1.3,0,0,0,1.233.492c.863-.04,1.725-.02,2.6-.007a.356.356,0,0,0,.4-.256,3.357,3.357,0,1,1,3.76,4.565,1.913,1.913,0,0,0-.182.067C291.98,419.778,291.684,419.778,291.394,419.778Z" transform="translate(-263.155 -385.272)" fill="gray" />
                  <path id="Path_5226" data-name="Path 5226" d="M1,317.128a4.789,4.789,0,0,1,.809-1.7,3.359,3.359,0,0,1,5.628.829.465.465,0,0,0,.539.35c.472-.027.944-.007,1.4-.007.263.674.512,1.328.782,2.022-.741,0-1.476.013-2.211-.007a.426.426,0,0,0-.492.317,3.348,3.348,0,0,1-3.262,2.042,3.39,3.39,0,0,1-3-2.325l-.182-.566C1,317.755,1,317.445,1,317.128Z" transform="translate(-1 -293.135)" fill="gray" />
                  <path id="Path_5227" data-name="Path 5227" d="M166.153,218.082A7.077,7.077,0,1,1,159.1,211,7.077,7.077,0,0,1,166.153,218.082Zm-6.066,4.36c0-.849.013-1.658-.007-2.46a.5.5,0,0,1,.337-.553,2.994,2.994,0,0,0,1.483-3.788,3.023,3.023,0,1,0-4.057,3.838.352.352,0,0,1,.229.384c-.007.755-.007,1.5,0,2.258,0,.108.007.216.013.324C158.76,222.442,159.407,222.442,160.087,222.442Z" transform="translate(-141.823 -196.846)" fill="#b0b0b0" />
                  <path id="Path_5228" data-name="Path 5228" d="M23.657,169.794l-.236-.216-1.88-1.88a.312.312,0,0,0-.4-.094,3.36,3.36,0,1,1,1.725-1.752.332.332,0,0,0,.1.431c.425.411.849.829,1.26,1.267a.432.432,0,0,1,.067.364C24.1,168.527,23.886,169.14,23.657,169.794Z" transform="translate(-15.388 -150.377)" fill="gray" />
                  <path id="Path_5229" data-name="Path 5229" d="M244.114,282.087a1.008,1.008,0,1,1-1.024-.991A1.008,1.008,0,0,1,244.114,282.087Z" transform="translate(-225.85 -262.218)" fill="#b0b0b0" />
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">End-To-End Encrypted
                  Transactions</h5> </div>
            </div>
          </div>
        </div>
        <div className="binanceflexbox" >
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="37.377" height="31.849" viewBox="0 0 37.377 31.849">
                  <g id="HTTPS_authentication" data-name="HTTPS authentication" transform="translate(0.004 0)">
                    <path id="Path_8911" data-name="Path 8911" d="M674.585,281.07c4.532,0,9.065-.018,13.6.008A4.9,4.9,0,0,1,692.8,284a4.585,4.585,0,0,1,.471,2.071c0,7.278.012,14.557,0,21.835a5.007,5.007,0,0,1-5.027,5q-13.643.013-27.287,0a5.012,5.012,0,0,1-5.042-5.013q-.024-10.9,0-21.8a5.014,5.014,0,0,1,5.043-5.012C665.5,281.063,670.042,281.071,674.585,281.07Zm-.008,29.641h13.506a2.847,2.847,0,0,0,3-2.974q0-10.75,0-21.5a2.843,2.843,0,0,0-2.967-2.972q-13.521-.005-27.043,0a3.129,3.129,0,0,0-.847.1,2.817,2.817,0,0,0-2.118,2.838c-.006,2.123,0,4.247,0,6.37v15.1a2.974,2.974,0,0,0,.6,1.917,2.911,2.911,0,0,0,2.434,1.12Q667.855,310.706,674.577,310.711Z" transform="translate(-655.902 -281.064)" fill="gray" />
                    <path id="Path_8912" data-name="Path 8912" d="M742.278,625.431H729.261a1.13,1.13,0,0,1-1.164-.67,1.072,1.072,0,0,1,.69-1.466,2.043,2.043,0,0,1,.544-.059h25.942a1.152,1.152,0,0,1,1.247.732,1.083,1.083,0,0,1-.92,1.454c-.132.011-.265.008-.4.008Z" transform="translate(-723.588 -602.276)" fill="gray" />
                    <path id="Path_8913" data-name="Path 8913" d="M733.366,466.494v-.762c0-.561.006-1.123,0-1.684a.481.481,0,0,1,.417-.551,2.593,2.593,0,0,1,.782-.006.5.5,0,0,1,.436.567q-.012,3.2,0,6.4a.53.53,0,0,1-.459.587,1.768,1.768,0,0,1-.574.031c-.454-.056-.6-.234-.6-.685v-2.615h-2.1v2.663c0,.38-.122.545-.494.618a1.7,1.7,0,0,1-.575.013c-.421-.064-.556-.235-.556-.659V464.1c0-.418.149-.593.565-.647a1.92,1.92,0,0,1,.517,0c.4.058.542.235.543.636,0,.7.01,1.408,0,2.112,0,.234.072.3.3.292C732.147,466.485,732.736,466.494,733.366,466.494Z" transform="translate(-725.123 -452.268)" fill="gray" />
                    <path id="Path_8914" data-name="Path 8914" d="M1115.69,469.96a3.526,3.526,0,0,1-2.05-.632.875.875,0,0,1-.145-1.436.518.518,0,0,1,.717-.079c.179.116.339.263.515.385a1.552,1.552,0,0,0,1.51.2.611.611,0,0,0,.232-1.069,2.884,2.884,0,0,0-.628-.43c-.394-.214-.806-.395-1.2-.6a2.2,2.2,0,0,1-1.144-2.758,1.92,1.92,0,0,1,1.177-1.168,4.015,4.015,0,0,1,2.845-.034.767.767,0,0,1,.557,1.049c-.17.505-.447.635-.935.417a2.284,2.284,0,0,0-1.423-.234,1.355,1.355,0,0,0-.56.273.384.384,0,0,0-.029.6,2.717,2.717,0,0,0,.614.44c.5.276,1.023.506,1.513.8a2.313,2.313,0,0,1,1.043,2.433,2.147,2.147,0,0,1-1.731,1.729C1116.27,469.9,1115.98,469.921,1115.69,469.96Z" transform="translate(-1085.167 -451.004)" fill="gray" />
                    <path id="Path_8915" data-name="Path 8915" d="M1023.23,468.515v2c0,.532-.158.71-.69.758a1.46,1.46,0,0,1-.424-.026c-.386-.081-.509-.237-.51-.637v-6.428c0-.334.162-.547.49-.546a28.218,28.218,0,0,1,3.079.094,1.8,1.8,0,0,1,1.591,1.522,3.355,3.355,0,0,1-.083,1.871,1.925,1.925,0,0,1-1.438,1.249,6.641,6.641,0,0,1-1.119.137C1023.84,468.532,1023.56,468.515,1023.23,468.515Zm.024-3.484v2.228h.843c.852,0,1.176-.36,1.11-1.218-.059-.761-.316-1-1.078-1.009-.289,0-.569,0-.879,0Z" transform="translate(-999.2 -452.454)" fill="gray" />
                    <path id="Path_8916" data-name="Path 8916" d="M924.293,464.619v5.533c0,.456-.142.631-.588.7a1.583,1.583,0,0,1-.485,0c-.415-.07-.556-.243-.556-.664,0-1.725-.008-3.449.006-5.174,0-.3-.066-.421-.387-.393a8.565,8.565,0,0,1-1.01,0c-.358-.011-.549-.259-.556-.7s.19-.709.544-.711q2.22-.014,4.439,0c.353,0,.547.287.538.716s-.2.684-.562.692C925.23,464.627,924.783,464.619,924.293,464.619Z" transform="translate(-904.494 -452.043)" fill="gray" />
                    <path id="Path_8917" data-name="Path 8917" d="M829.79,464.617H828.5c-.462,0-.663-.225-.66-.718,0-.475.2-.7.651-.7h4.226c.437,0,.646.239.642.709s-.206.7-.643.708c-.408.006-.816.009-1.224.015-.017,0-.034.02-.081.048v5.457c0,.432-.084.562-.427.675-.4.131-.958.039-1.106-.218a.975.975,0,0,1-.088-.468c-.006-1.7,0-3.389,0-5.084Z" transform="translate(-817.31 -452.04)" fill="gray" />
                    <path id="Path_8918" data-name="Path 8918" d="M871.2,355.231a1.092,1.092,0,1,1,1.1-1.079,1.092,1.092,0,0,1-1.1,1.079Z" transform="translate(-856.987 -348.637)" fill="gray" />
                    <path id="Path_8919" data-name="Path 8919" d="M728.128,353.049a1.092,1.092,0,1,1-1.092,1.084A1.092,1.092,0,0,1,728.128,353.049Z" transform="translate(-722.679 -348.639)" fill="gray" />
                    <path id="Path_8920" data-name="Path 8920" d="M799.725,355.248a1.091,1.091,0,1,1,1.093-1.089A1.091,1.091,0,0,1,799.725,355.248Z" transform="translate(-789.905 -348.655)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">HTTPS Authentication</h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Browser_detection_security" data-name="Browser detection security" xmlns="http://www.w3.org/2000/svg" width="34.507" height="33.149" viewBox="0 0 34.507 33.149">
                  <path id="Path_5217" data-name="Path 5217" d="M0,119.619V104.05c5.156,0,10.312-.007,15.467.013a1.506,1.506,0,0,0,1.436-.782c.634-1.018,1.328-2,1.981-3.006A.536.536,0,0,1,19.4,100q7.36.01,14.726.007H34.5V119.12a3.026,3.026,0,0,1-3.12,3.127c-1.5.007-3.013,0-4.516,0H24.546c.047-.142.088-.256.115-.371a20.568,20.568,0,0,0,.593-2.891,47.093,47.093,0,0,0,.061-4.778,2.771,2.771,0,0,0-1.779-2.568,15.526,15.526,0,0,0-4.576-1.179,16.391,16.391,0,0,0-7.757,1.078,3.034,3.034,0,0,0-2.035,2.918c-.007,1.146-.054,2.291.013,3.437.061,1.011.229,2.022.384,3.033a11.627,11.627,0,0,0,.377,1.328H3.208c-.135,0-.27,0-.4-.007a3.044,3.044,0,0,1-2.682-2.17C.081,119.922.04,119.774,0,119.619Z" transform="translate(0 -93.945)" fill="gray" />
                  <path id="Path_5218" data-name="Path 5218" d="M0,12.78c.054-.182.094-.364.155-.546a3.022,3.022,0,0,1,2.891-2.076c2.291-.007,4.583,0,6.874,0H31.353a3,3,0,0,1,3.12,2.628,9.85,9.85,0,0,1,.013,1.415H34.1c-5.109,0-10.224.007-15.333-.007a1.292,1.292,0,0,0-1.2.64c-.654,1.018-1.348,2.015-2.008,3.033a.727.727,0,0,1-.694.384c-4.954-.013-9.907-.007-14.861-.007Q0,15.506,0,12.78Zm5.061,2.426a1.011,1.011,0,0,0,.007-2.022,1.011,1.011,0,1,0-.007,2.022Zm5.048-1a1.011,1.011,0,1,0-1.018.991A1.011,1.011,0,0,0,10.109,14.209Zm3.013-1.018a1.016,1.016,0,0,0-.991,1.018,1.011,1.011,0,1,0,.991-1.018Z" transform="translate(0 -10.156)" fill="gray" />
                  <path id="Path_5219" data-name="Path 5219" d="M177.949,288.281a12.527,12.527,0,0,1-.4,3.922,10.238,10.238,0,0,1-5.014,6.194,1.211,1.211,0,0,1-1.3,0,10.1,10.1,0,0,1-5.412-8.91c-.04-1.166-.007-2.339-.007-3.5a1.034,1.034,0,0,1,.728-1.058,14.189,14.189,0,0,1,10.608-.02,1.045,1.045,0,0,1,.8,1.139Zm-6.807,3.067c-.256-.283-.465-.553-.708-.782a1.009,1.009,0,0,0-1.4,0,.991.991,0,0,0-.013,1.422c.472.492.957.977,1.456,1.449a.949.949,0,0,0,1.341.04q1.516-1.466,2.979-2.979a.906.906,0,0,0,.162-1.038.957.957,0,0,0-.856-.613,1.06,1.06,0,0,0-.869.371C172.557,289.919,171.877,290.6,171.142,291.348Z" transform="translate(-154.63 -265.438)" fill="#b0b0b0" />
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Browser Detection
                  Security</h5>
              </div></div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="34.506" height="34.517" viewBox="0 0 34.506 34.517">
                  <g id="DDoS" transform="translate(0 0.003)">
                    <path id="Path_5220" data-name="Path 5220" d="M-.1,245.106a5.859,5.859,0,0,1,.762-.768c1.887-1.281,3.788-2.534,5.681-3.8a1.022,1.022,0,0,1,1.644.883c-.007.29,0,.58,0,.9h6.126V240.2h6.059v2.123h6.146v-.944a.994.994,0,0,1,.546-.937.962.962,0,0,1,1.051.067c2,1.334,4,2.655,5.991,4a1,1,0,0,1,0,1.732q-2.992,2.012-5.991,4a.969.969,0,0,1-1.078.054.99.99,0,0,1-.519-.917v-.95H20.213v2.008h.93a1,1,0,0,1,.944.539.982.982,0,0,1-.074,1.078c-1.328,1.988-2.649,3.983-3.983,5.958a2.971,2.971,0,0,1-.6.512h-.54a2.925,2.925,0,0,1-.58-.479c-1.348-1.995-2.682-4.01-4.017-6.018a.961.961,0,0,1-.061-1.051.989.989,0,0,1,.937-.539h.937v-1.995H7.994v.9a1.021,1.021,0,0,1-1.644.877c-1.894-1.267-3.794-2.521-5.675-3.8a5.133,5.133,0,0,1-.762-.768C-.1,245.47-.1,245.288-.1,245.106Z" transform="translate(0.1 -224.009)" fill="gray" />
                    <path id="Path_5221" data-name="Path 5221" d="M154.967-.024V3.251c0,.586-.007,1.166,0,1.752a.992.992,0,0,0,.984,1.031c1.368.013,2.743.013,4.111,0a.991.991,0,0,0,.97-1.078c.007-1.53,0-3.053,0-4.583v-.4h2.878a1.036,1.036,0,0,1,1.166,1.159v10.84c0,.357.007.721,0,1.078a1.008,1.008,0,0,1-1.051,1.065q-6.025.01-12.057,0a1,1,0,0,1-1.038-1.045q-.01-6.025,0-12.057a.981.981,0,0,1,.937-1.038C152.885-.045,153.909-.024,154.967-.024Z" transform="translate(-140.747 0.033)" fill="#b0b0b0" />
                    <path id="Path_5222" data-name="Path 5222" d="M241.3.3h1.981v4H241.3Z" transform="translate(-225.031 -0.278)" fill="#b0b0b0" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">DDoS Migration</h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="34.512" height="32.345" viewBox="0 0 34.512 32.345">
                  <g id="CSRF_protection" data-name="CSRF protection" transform="translate(0 -0.005)">
                    <path id="Path_5234" data-name="Path 5234" d="M316.226,23.744c-.061.3-.115.613-.189.917a7.025,7.025,0,1,1,.129-2.487.933.933,0,0,0,.067.222C316.226,22.841,316.226,23.292,316.226,23.744Zm-8.094-.054c-.458-.472-.869-.917-1.3-1.334a1.006,1.006,0,1,0-1.429,1.415q.991,1.031,2.022,2.022a.969.969,0,0,0,1.422.007c1.361-1.341,2.7-2.7,4.05-4.044a.954.954,0,0,0,.263-.957,1,1,0,0,0-1.732-.411c-.876.856-1.732,1.725-2.6,2.6-.228.217-.433.437-.7.707Z" transform="translate(-281.721 -15.986)" fill="#b0b0b0" />
                    <path id="Path_5235" data-name="Path 5235" d="M18.443,136.083a9,9,0,0,0,5.2,7.2,8.9,8.9,0,0,0,8.849-.634c.007.115.013.2.013.283v14.254a3,3,0,0,1-2.285,3.033,3.366,3.366,0,0,1-.9.108q-13.051.01-26.109,0A3.043,3.043,0,0,1,.03,157.158q-.01-8.957,0-17.92a3.016,3.016,0,0,1,3.1-3.154q7.562-.02,15.13-.007C18.308,136.077,18.362,136.083,18.443,136.083Zm-6.477,12.974a4.043,4.043,0,0,0-5.392-6.012,3.911,3.911,0,0,0-1.462,2.642,4.067,4.067,0,0,0,1.166,3.377c-.081.067-.135.121-.2.168A4.643,4.643,0,0,0,4.191,152.3a21.918,21.918,0,0,0-.094,3.12.953.953,0,0,0,1.031.863q3.993.01,7.986,0a1.006,1.006,0,0,0,1.065-1.058c.007-.687.013-1.368-.007-2.056a4.936,4.936,0,0,0-1.57-3.565c-.2-.195-.42-.363-.636-.552Zm11.444,3.188h3.909a1.016,1.016,0,1,0,.007-2.015H19.474a1.035,1.035,0,0,0-1.045.647,1.013,1.013,0,0,0,1,1.375C20.754,152.252,22.082,152.245,23.41,152.245Zm0,4.044h3.943a1.013,1.013,0,1,0,.067-2.022c-1.227-.013-2.446-.007-3.673-.007h-4.28a1.014,1.014,0,1,0-.034,2.022C20.761,156.3,22.082,156.289,23.41,156.289Zm-3.033-9.1a1.008,1.008,0,1,0-1,1.011,1.008,1.008,0,0,0,1-1.011Zm3.026-1a1.008,1.008,0,1,0,1.018,1,1.008,1.008,0,0,0-1.018-1Zm4.044,0a1.008,1.008,0,1,0,1.018,1,1.008,1.008,0,0,0-1.018-1Z" transform="translate(-0.025 -127.982)" fill="gray" />
                    <path id="Path_5236" data-name="Path 5236" d="M90.051,350.191a4.486,4.486,0,0,1,.438-2.568,3.088,3.088,0,0,1,3.222-1.382,3.015,3.015,0,0,1,2.339,2.487c.054.479.054.957.081,1.462Z" transform="translate(-83.974 -323.926)" fill="gray" />
                    <path id="Path_5237" data-name="Path 5237" d="M109.137,258.122a2.019,2.019,0,1,1-2.015-2.022A2.04,2.04,0,0,1,109.137,258.122Z" transform="translate(-98.018 -239.917)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">CSRF Protection</h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="32.515" height="32.521" viewBox="0 0 32.515 32.521">
                  <g id="Data_encryption" data-name="Data encryption" transform="translate(0.001 0.005)">
                    <path id="Path_8925" data-name="Path 8925" d="M949.434,545.97c0-1.512.006-3.025-.006-4.537a.768.768,0,0,1,.247-.609c1.711-1.7,3.408-3.411,5.124-5.106a1.083,1.083,0,0,0,.375-.949,5.652,5.652,0,0,1,3.545-5.586,5.71,5.71,0,0,1,7.8,4.479,2.4,2.4,0,0,0,.06.241v1.143c-.022.1-.051.2-.066.3a5.718,5.718,0,0,1-6.238,4.8c-.1-.012-.249-.007-.288.05-.24.347-.586.314-.936.308-.513-.007-1.027,0-1.562,0v2.736h-2.658v2.726Zm12.36-10.555v-1.877h-1.863v1.877Z" transform="translate(-934.065 -513.454)" fill="#b0b0b0" />
                    <path id="Path_8926" data-name="Path 8926" d="M768,407.25a2.423,2.423,0,0,0,1.265,1.181,13.235,13.235,0,0,0,3.683,1.016,26.3,26.3,0,0,0,4.959.4,25.916,25.916,0,0,0,6.977-1.009,8.366,8.366,0,0,0,1.17-.481,2.42,2.42,0,0,0,1.125-1.016v.3c0,1.2-.021,2.392.009,3.587a1.489,1.489,0,0,1-.666,1.321,6.752,6.752,0,0,1-2.245,1,25.708,25.708,0,0,1-7.985.787,21.7,21.7,0,0,1-5.382-.792,13.267,13.267,0,0,1-1.569-.589,2.568,2.568,0,0,1-1.341-1.2Z" transform="translate(-768 -402.233)" fill="gray" />
                    <path id="Path_8927" data-name="Path 8927" d="M778.923,348c.7.064,1.409.115,2.112.194a15.314,15.314,0,0,1,4.4,1.03,5.357,5.357,0,0,1,1.337.826,1,1,0,0,1,.009,1.623,4.51,4.51,0,0,1-1.7.964,19.85,19.85,0,0,1-5.5,1.013,29.521,29.521,0,0,1-6.389-.258,13.715,13.715,0,0,1-3.715-1.014A2.854,2.854,0,0,1,768,351.112V350.6a2.432,2.432,0,0,1,1.086-1.065,7.63,7.63,0,0,1,.831-.392,16.923,16.923,0,0,1,4.257-.955c.693-.076,1.388-.129,2.082-.193Z" transform="translate(-768 -348)" fill="gray" />
                    <path id="Path_8928" data-name="Path 8928" d="M768,483a2.423,2.423,0,0,0,1.265,1.181,13.239,13.239,0,0,0,3.683,1.016,26.293,26.293,0,0,0,4.959.4,25.916,25.916,0,0,0,6.977-1.009,8.366,8.366,0,0,0,1.17-.481,2.42,2.42,0,0,0,1.125-1.016v.3c0,1.185-.017,2.371.008,3.555a1.319,1.319,0,0,1-.62,1.241,9.623,9.623,0,0,1-1.689.85,18.771,18.771,0,0,1-5.128.946,29.822,29.822,0,0,1-6.08-.181,16.3,16.3,0,0,1-3.722-.884c-.207-.079-.41-.17-.609-.268a2.716,2.716,0,0,1-1.34-1.2Z" transform="translate(-768 -471.569)" fill="gray" />
                    <path id="Path_8929" data-name="Path 8929" d="M768,557.25a2.12,2.12,0,0,0,1.018,1.085,10.754,10.754,0,0,0,3.445,1.131,28.949,28.949,0,0,0,8.026.315,16.745,16.745,0,0,0,4.9-1.074,5.673,5.673,0,0,0,1.106-.609,5,5,0,0,0,.666-.682c0,.027.013.112.013.2,0,.889-.023,1.778.008,2.666a2.433,2.433,0,0,1-1.049,2.122,7.945,7.945,0,0,1-3,1.318,23.621,23.621,0,0,1-7.811.535,15.868,15.868,0,0,1-4.812-1.043,9.092,9.092,0,0,1-1.487-.831A2.523,2.523,0,0,1,768,560.87Z" transform="translate(-768 -539.532)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Data Encryption</h5>
              </div> </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SecurityofOur